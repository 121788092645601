.c-project {
    $c: &;

    @apply .w-full .h-full .relative .text-0;

    &__wrapper {
        @apply .relative .h-full .overflow-hidden;
    }

    &__inner,
    &__overlay {
        @apply .p-10;
    }

    &__inner {
        @apply .relative .h-full .z-10 .bg-white .border .border-grey-light .transition-all .duration-300 .ease-in-out;
    }

    &__picture {
        width: 172px;
        height: 172px;

        @apply .relative .rounded-full .overflow-hidden .z-10 .transition-transform .duration-700 .ease-in-out;

        &:after {
            content: '';

            @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .opacity-0 .bg-secondary .transition-all .duration-300 .ease-in-out;
        }
    }

    &__body {
        @apply .relative .text-center .transition-all .duration-300 .ease-in-out;
    }

    &__overlay {
        transform: translateY(8%);

        @apply .text-center .-z-1 .absolute .top-0 .right-0 .bottom-0 .left-0 .opacity-0 .transition-all .duration-500 .ease-in-out;
    }

    &__title,
    &__text {
        @apply .transition-all .duration-500 .ease-in-out;
    }

    &:after {
        content: '';

        @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .-m-1.25 .opacity-0 .transition-all .duration-500 .ease-in-out;
    }

    &:hover,
    &:active {
        #{$c} {
            &__inner {
                @apply .border-transparent;
            }

            &__picture {
                transform: scale(4);

                &:after {
                    @apply .opacity-30;
                }
            }

            &__body {
                @apply .opacity-0 .-z-1;
            }

            &__overlay {
                transform: translateY(0);

                @apply .opacity-100 .z-30;
            }

            &__title,
            &__text {
                @apply .text-white;
            }
        }

        &:after {
            @apply .opacity-100;
        }
    }
}
