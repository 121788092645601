.c-link {
    $c: &;

    @apply .text-0 .relative .inline-flex .items-center;

    &__label,
    &__icon {
        @apply .inline-block .font-bold .text-base .leading-tighter .transition-all .duration-300 .ease-in-out;
    }

    &--primary {
        @apply .pb-2;

        #{$c} {
            &__label {
                @apply .text-secondary;
            }
        }

        &:after {
            content: '';

            @apply .absolute .left-0 .bottom-0 .w-5 .h-0.5 .transition-all .duration-300 .ease-in-out;
        }

        &:hover,
        &:active,
        &.hover {
            &:after {
                @apply .w-full;
            }
        }
    }

    &--secondary {
        &:hover,
        &:active,
        &.hover {
            #{$c} {
                &__label,
                &__icon .c-icon {
                    @apply .text-secondary;
                }
            }
        }
    }

    &--tertiary {
        @apply .border-b .border-secondary;

        #{$c} {
            &__label,
            &__icon .c-icon {
                @apply .text-secondary .font-normal .text-lg .leading-snug;

                @screen md {
                    @apply .text-xl;
                }
            }
        }
    }

    &--quaternary {
        #{$c} {
            &__label,
            &__icon .c-icon {
                @apply .text-secondary .duration-500;
            }
        }

        &:hover,
        &:active,
        &.hover {
            #{$c} {
                &__label,
                &__icon .c-icon {
                    @apply .text-white;
                }
            }
        }
    }

    &.has-icon-after {
        #{$c} {
            &__icon {
                @apply .ml-2.5;
            }
        }
    }

    &.has-icon-before {
        #{$c} {
            &__icon {
                @apply .mr-2.5;
            }
        }
    }
}
