.c-topic {
    @apply .w-full .p-5;
    
    &__wrapper {
        @apply .flex .items-center .gap-7;
    }
    &__picture {
        flex-shrink: 0;
        @apply .w-30.5 .h-30.5;
    }
}