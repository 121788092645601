.c-form-field {
    $c: &;

    @apply .text-0;

    &__wrapper {
        @apply .relative;
    }

    &__label,
    &__input {
        @apply .text-base .font-bold .leading-tighter .cursor-text .px-5 .py-4.5 .transition-all .duration-300 .ease-in-out;
    }

    &__label {
        @apply .absolute .top-0 .bottom-0 .inline-block .left-0 .text-grey .text-right;

        &.is-required {
            &:after {
                content: '*';

                @apply .font-primary .text-base .leading-tight .font-bold .ml-1 .transition-all .duration-300 .ease-in-out;
            }
        }
    }

    &__input {
        @apply .text-secondary .border .border-grey-light .w-full;

        &:focus,
        &:not([value='']):not(:focus) {
            @apply .border-grey;

            + #{$c} {
                &__label:nth-child(n) {
                    transform: translateY(-12px);

                    @apply .pb-0 .bottom-auto .text-xxs;

                    &.is-required {
                        &:after {
                            @apply .text-xxs .ml-0.5;
                        }
                    }
                }
            }
        }
    }

    &.has-errors {
        #{$c} {
            &__input {
                @apply .border-error;
            }
        }
    }
}
