.c-mobile-nav {
    z-index: -1;

    @apply .bg-secondary-dark .opacity-0 .fixed .w-full .h-full .top-0 .transition-opacity .duration-200 .ease-linear .overflow-y-scroll;

    &.is-active {
        @apply .transition-all .z-50 .opacity-100;
    }

    @screen lg {
        @apply .hidden;
    }
}
