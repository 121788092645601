// -----------------------------------------------------------------------------
// elements/body
// -----------------------------------------------------------------------------

body {
    @apply .text-grey .font-primary .font-normal .text-lg .leading-snug .bg-white;

    @screen md {
        @apply .text-xl;
    }
}
