.c-form {
    $c: &;

    @apply .relative .text-0;

    &__rows {
        @apply .-mb-4;
    }

    &__row {
        @apply .pb-4;
    }

    &__columns {
        @apply .-mb-4 .-ml-4;

        @screen lg {
            @apply .flex;
        }
    }

    &__column {
        @apply .flex-1 .pb-4 .pl-4;
    }

    &[data-type="newsletter"] {
        #{$c} {
            &__columns {
                > *:nth-child(1) {flex-basis: 70%;}
                > *:nth-child(2) {flex-basis: 30%;}
            }

            &-field--email {
                input {
                    @apply .bg-transparent .border-2 .border-kbrv-secondary .h-15;
                }
                
                label {
                    top: auto; bottom: 1px;
                }
            }
        }

        .c-button {
            @apply .bg-kbrv-secondary;

            &__label {
                @apply .text-white;
            }
            &__icon {
                @apply .text-white;
            }
        }
    }
}
