.c-slider {
    $c: &;

    @apply .relative .text-0;

    &__list {
    }

    &__item {
    }

    &__numbers {
        @apply .flex .items-end .-ml-4;
    }

    &__number {
        @apply .pl-4;

        &.is-active {
            #{$c} {
                &__number-link {
                }

                &__number-label {
                    @apply .text-white;
                }
            }
        }
    }

    &__number-link {
        &:hover,
        &:active {
            #{$c} {
                &__number-label {
                    @apply .text-white;
                }
            }
        }
    }

    &__number-label {
        @apply .text-white-60 .transition-all .duration-300 .ease-in-out;
    }

    &__nav-list {
        @apply .flex .-ml-2.5
    }

    &__nav-item {
        @apply .pl-2.5;
    }

    &__nav-button {
        @apply .w-12 .h-12 .rounded-full .border .border-grey .flex .items-center .justify-center .bg-transparent .text-grey-light;

        &:hover,
        &:active {
            .c-icon {
                @apply .text-white;
            }
        }

        &--prev {
            transform: rotate(180deg);
        }

        &--alt {
            @apply .border-grey-light;
        }
    }
}
