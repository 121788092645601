.c-page {
    $c: &;

    @apply .relative;

    &.mobile-nav-open {
        @apply .fixed .w-full .h-full .overflow-hidden;
    }

    &--contact {
        .c-content {
            @apply .overflow-hidden;
        }
    }
}


.c-overlay {
    &:after {
        content: '';

        @apply .bg-secondary .-z-1 .opacity-0 .absolute .top-0 .right-0 .bottom-0 .left-0 .transition-all .duration-300 .ease-in-out;
    }

    &.is-active {
        .c-header {
            @apply .z-50;
        }

        &:after {
            @apply .z-10 .opacity-30;
        }
    }
}
