// -----------------------------------------------------------------------------
// elements/button
// -----------------------------------------------------------------------------

button {
    @apply .transition-all .duration-300 .ease-in-out;
    
    &:focus {
        outline: none;
    }

    &:disabled,
    &[disabled] {
        @apply .cursor-not-allowed;
    }
}
