.c-cta {
    $c: &;

    @apply .text-0 .py-28;

    &__inner {
        @apply .max-w-xl .mx-auto .text-center;
    }

    &__title,
    &__text {
        @apply .text-white;
    }
}
