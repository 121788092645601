.c-button {
    $c: &;

    @apply .relative .text-0 .py-4.75 .px-6 .border-2 .border-transparent .inline-flex .items-center .justify-center .w-full .transition-all .duration-300 .ease-in-out;

    @screen md {
        @apply .w-auto;
    }

    &__label,
    &__icon {
        @apply .inline-block .transition-all .duration-300 .ease-in-out;
    }

    &__label {
        @apply .text-base .leading-tighter .font-bold;
    }

    &__icon {
    }

    &--primary {
        #{$c} {
            &__label,
            &__icon {
                @apply .text-white .relative .z-10;
            }

            &__label {
            }

            &__icon {
            }
        }

        &:after {
            content: '';
            transform: scaleY(0);
            transform-origin: bottom;

            @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .bg-secondary .-m-0.5 .transition-all .duration-300 .ease-in-out;
        }

        &:hover,
        &:active {
            @apply .bg-secondary;

            #{$c} {
            }

            &:after {
                transform: scaleY(1);
            }
        }
    }

    &--secondary {
        @apply .bg-transparent;

        #{$c} {
            &__label,
            &__icon {
                @apply .text-secondary;
            }

            &__label {
            }

            &__icon {
            }
        }

        &:hover,
        &:active {
            @apply .border-secondary;
        }
    }

    &--tertiary {
        @apply .bg-transparent .border-white-30;

        #{$c} {
            &__label,
            &__icon {
                @apply .text-white;
            }

            &__label {
            }

            &__icon {
            }
        }

        &:hover,
        &:active {
            @apply .border-white;
        }
    }

    &.has-icon-after {
        #{$c} {
            &__icon {
                @apply .ml-2.5;
            }
        }
    }

    &.has-icon-before {
        #{$c} {
            &__icon {
                @apply .mr-2.5;
            }
        }
    }

    &.has-spinning-icon {
        &:hover,
        &:active {
            #{$c} {
                &__icon {
                    transform: rotate(360deg);
                }
            }
        }
    }

    &.is-small {
        @apply .py-3 .px-4;

        #{$c} {
            &__label {
                @apply .text-sm;
            }
        }
    }
}
