/* purgecss start ignore */
@tailwind base;

@tailwind components;

@import "01-generics/_base.scss";
@import "01-generics/_fonts.scss";
@import "02-vendors/_aos.scss";
@import "02-vendors/_hamburgers.scss";
@import "02-vendors/_magnific-popup.scss";
@import "02-vendors/_slick.scss";
@import "03-elements/_body.scss";
@import "03-elements/_button.scss";
@import "03-elements/_heading.scss";
@import "03-elements/_input.scss";
@import "03-elements/_link.scss";
@import "04-components/_become-a-partner.scss";
@import "04-components/_button.scss";
@import "04-components/_container.scss";
@import "04-components/_content.scss";
@import "04-components/_cookie-consent.scss";
@import "04-components/_cta.scss";
@import "04-components/_footer.scss";
@import "04-components/_form-field.scss";
@import "04-components/_form.scss";
@import "04-components/_funding.scss";
@import "04-components/_header.scss";
@import "04-components/_hero.scss";
@import "04-components/_highlight.scss";
@import "04-components/_icon.scss";
@import "04-components/_link.scss";
@import "04-components/_logo.scss";
@import "04-components/_mobile-nav.scss";
@import "04-components/_nav.scss";
@import "04-components/_outlined-title.scss";
@import "04-components/_page.scss";
@import "04-components/_partner.scss";
@import "04-components/_partners.scss";
@import "04-components/_picture.scss";
@import "04-components/_popup.scss";
@import "04-components/_presentation.scss";
@import "04-components/_progress-bar.scss";
@import "04-components/_project.scss";
@import "04-components/_scroll-indicator.scss";
@import "04-components/_session.scss";
@import "04-components/_slider.scss";
@import "04-components/_spec.scss";
@import "04-components/_specs.scss";
@import "04-components/_text.scss";
@import "04-components/_think-tank.scss";
@import "04-components/_topic.scss";
/* purgecss end ignore */

@tailwind utilities;
