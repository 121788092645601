// -----------------------------------------------------------------------------
// elements/heading
// -----------------------------------------------------------------------------

.h0,
h1,
h2,
h3,
h4,
h5,
h6 {
    @apply .font-primary .font-black .text-secondary .leading-tighter;

    strong {
        @apply .font-black;
    }
}

.h0 {
    @apply .leading-tighter;

    @screen md {
    }

    @screen lg {
    }

    // 100px
    @screen xl {
        @apply .text-10xl;
    }
}

h1,
.h1 {
    // 30px
    @apply .text-3xl;

    // 40px
    @screen md {
        @apply .text-4xl;
    }
    // 48px
    @screen lg {
        @apply .text-5xl;
    }
}

h2,
.h2 {
    // 26px
    @apply .text-2xl;

    // 30px
    @screen md {
        @apply .text-3xl;
    }

    // 40px
    @screen lg {
        @apply .text-4xl;
    }
}

h3,
.h3 {
    // 22px
    @apply .text-xxl .leading-tight;

    // 26px
    @screen md {
        @apply .text-2xl;
    }

    // 30px
    @screen lg {
        @apply .text-3xl;
    }
}

h4,
.h4 {
    // 20px
    @apply .text-xl .leading-snug;

    // 22px
    @screen md {
        @apply .text-xxl;
    }

    // 26px
    @screen lg {
        @apply .text-2xl;
    }
}

h5,
.h5 {
    // 18px
    @apply .text-lg .leading-snug;

    // 20px
    @screen md {
        @apply .text-xl;
    }
}

h6,
.h6 {
    // 16px
    @apply .text-base .leading-snug;

    // 18px
    @screen md {
        @apply .text-lg;
    }
}
