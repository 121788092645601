.c-funding {
    $c: &;

    @apply .text-0 .relative .bg-white .w-full;

    &__picture {
        @apply .h-full;
    }

    &__body {
        @apply .p-12;

        @screen md {
            @apply .px-0 .py-16;
        }

        @screen lg {
            @apply .py-20;
        }
    }

    @screen md {
        &:after {
            left: 100%;

            content: '';

            @apply .absolute .bg-white .top-0 .right-0 .bottom-0 .w-full .transition-all .duration-300 .ease-in-out;
        }
    }

    @screen md {
        &:hover,
        &:active {
            @apply .bg-grey-lighter;

            &:after {
                @apply .bg-grey-lighter;
            }
        }
    }
}
