.c-picture {
    $c: &;

    @apply .inline-block .relative .text-0 .h-full .overflow-hidden;

    &__image {
        @apply .relative .h-full .transition-all .duration-500 .ease-in-out;

        &.lazyload {
            filter: blur(4px);

            @apply .opacity-0;
        }

        &.lazyloaded {
            filter: blur(0);

            @apply .opacity-100;

            + #{$c} {
                &__overlay {
                    transform: translateX(100%);
                }
            }
        }
    }

    &__overlay {
        transition: transform .8s 300ms cubic-bezier(.25,.74,.22,.99);
        transform: translateX(0);

        @apply .bg-white .absolute .top-0 .right-0 .bottom-0 .left-0 .w-full .h-full;
    }

    &.is-absolute {
        #{$c} {
            &__image {
                @apply .absolute .top-0 .right-0 .bottom-0 .left-0;
            }
        }
    }

    &.is-relative {
        #{$c} {
            &__image {
                @apply .relative;
            }
        }
    }
}
