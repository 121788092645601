.c-footer {
    $c: &;

    @apply .text-0 .bg-secondary-dark .py-7.5;

    a {
        @apply .text-base .leading-tighter .text-grey-light;

        &:hover,
        &:active {
            @apply .text-white;
        }
    }
}
