// -----------------------------------------------------------------------------
// generics/base
// -----------------------------------------------------------------------------

* {
    min-width: 0;
    min-height: 0;

    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-variant-ligatures: none;

    @apply .antialiased .scrolling-touch;
}

html,
body {
    @apply .h-full;
}

html {
    @apply .overflow-x-hidden;
}

b,
strong {
    @apply .font-bold;
}

::selection {
    @apply .text-white;
}

.grecaptcha-badge {
    @apply .invisible;
}
