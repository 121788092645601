.c-think-tank {
    $c: &;

    @apply .relative;

    &__content {
        @apply .flex .justify-center .items-center .absolute .inset-0;
    }

    &:hover,
    &:active {
        #{$c} {
            &__header {@apply .opacity-0;}
            &__reveal {@apply .opacity-100;}
            &__wrapper {@apply .mb-0;}
        }
    }

    &__reveal,
    &__wrapper,
    &__header {
        @apply .transition-all .duration-500 .ease-in-out;
    }
    &__reveal {
        @apply .flex .justify-center .items-center .absolute .inset-0 .opacity-0;
    }

    &__wrapper {
        max-width: 372px;

        @apply .-mb-64 .px-5;
    }

    &--blue &__reveal {background-color: hsla(200, 78%, 47%, 0.7);}
    &--green &__reveal {background-color: hsla(168, 78%, 33%, 0.7);}
}