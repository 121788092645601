.c-presentation {
    min-height: 320px;

    $c: &;

    @apply .text-0 .w-full .relative .flex .h-full;

    &__inner {
        @apply .px-12 .py-7.5 .bg-white .flex .h-full .z-10 .relative .overflow-hidden;

        &:after {
            content: '';
            transform: translateY(0);

            @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .bg-white .transition-all .duration-500 .ease-in-out;
        }
    }

    &__body {
        @apply .pt-10 .pb-7.5;
    }

    &__text-wrapper {
        @apply .opacity-0 .transition-all .duration-500 .ease-in-out;
    }

    &__speaker,
    &__title,
    &__text {
        @apply .transition-all .duration-500 .ease-in-out;
    }

    &__footer {
        @apply .relative .pt-7.5 .border-t-2 .border-grey-light;

        &:before {
            content: '';

            @apply .absolute .w-5 .h-0.5 .left-0 .top-0 .-mt-0.5 .transition-all .duration-500 .ease-in-out;
        }
    }

    &:after {
        content: '';

        @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .-m-1.25 .opacity-0 .transition-all .duration-500 .ease-in-out;
    }

    &:hover,
    &:active {
        #{$c} {
            &__text-wrapper {
                @apply .opacity-100;
            }

            &__footer {
                &:before {
                    @apply .w-full;
                }
            }
        }

        &:after {
            @apply .opacity-100;
        }
    }

    &.has-image {
        #{$c} {
            &__inner {
                &:after {
                    content: '';
                    transform: translateY(0);

                    @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .bg-white .transition-all .duration-500 .ease-in-out;
                }
            }

            &__picture {
                transform: scale(1.1);

                @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .w-full .h-full .transition-all .duration-500 .ease-in-out;

                &:after {
                    content: '';

                    @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .opacity-100 .bg-secondary;
                }
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__inner {
                    &:after {
                        transform: translateY(-100%);

                        @apply .-mt-px;
                    }
                }

                &__speaker,
                &__title,
                &__text {
                    @apply .text-white;
                }

                &__picture {
                    transform: scale(1);
                }
            }
        }
    }
}
