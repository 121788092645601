.c-content {
    $c: &;

    &__section {
        // @apply .relative;

        &--alt {
            @apply .bg-grey-lighter;
        }
    }
}
