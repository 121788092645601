.c-popup {
    $c: &;

    @apply .text-0 .max-w-1.5xl .bg-white .p-8 .relative .w-full .mx-auto;

    @screen md {
        @apply .p-12;
    }

    @screen lg {
        @apply .p-14;
    }

    @screen xl {
        @apply .p-20;
    }

    &__close-btn {
        @apply .absolute .top-0 .right-0 .text-grey .m-7.5;

        &:hover,
        &:active {
            .c-icon {
                @apply .text-secondary;
            }
        }
    }
}

.mfp-container {
    @apply .px-6;
}

.mfp-bg {
    @apply .bg-secondary;
}

.mfp-fade.mfp-bg {
    @apply .opacity-0 .transition-all .duration-300 .ease-in-out;
}

.mfp-fade.mfp-bg.mfp-ready {
    @apply .opacity-30;
}

.mfp-fade.mfp-bg.mfp-removing {
    @apply .opacity-0;
}

.mfp-fade.mfp-wrap .mfp-content {
    @apply .opacity-0 .transition-all .duration-300 .ease-in-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    @apply .opacity-100;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    @apply .opacity-0;
}
