.c-spec {
    $c: &;

    @apply .text-0;

    &__list {
        @apply .flex .flex-wrap .justify-between .-ml-2.5;
    }

    &__item {
        @apply .pl-2.5 .w-full;

        @screen lg {
            @apply .w-auto;
        }
    }

    &__label,
    &__value {
        @apply .inline-block .text-lg .leading-snug .text-secondary;

        @screen md {
            @apply .text-xl;
        }
    }

    &__label {
        @apply .font-black;
    }
}
