.c-partners {
    $c: &;

    @apply .text-0 .relative .border-t .border-l .border-grey-light;

    &__list {
        @apply .flex .flex-wrap;
    }

    &__item {
        @apply .border-r .border-b .border-grey-light;
    }
}
