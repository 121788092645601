.c-scroll-indicator {
    $c: &;

    @apply .relative .flex .flex-col;

    &__label {
        margin-top: -44px;
        margin-left: -14px;

        transform: rotate(90deg);

        @apply .absolute .inline-block .text-base .text-white .font-bold .leading-tighter;
    }

    &__icon {
        @apply .inline-block .relative .pt-3.5;

        &:before {
            left: 50%;

            content: '';
            animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;

            @apply .w-0.5 .h-5 .bg-white .absolute .top-0 .-ml-px .rounded-t-lg;
        }
    }

    &__arrow {
        @apply .text-white;
    }
}

@keyframes elasticus {
    0% {
        transform: scale(1, 0);
        transform-origin: 0 0;
    }
    50% {
        transform: scale(1, 1);
        transform-origin: 0 0;
    }
    50.1% {
        transform: scale(1, 1);
        transform-origin: 0 100%;
    }
    100% {
        transform: scale(1, 0);
        transform-origin: 0 100%;
    }
}
