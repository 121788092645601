.c-outlined-title {
    font-size: 190px;
    line-height: 160px;

    -webkit-text-stroke: 1px theme('colors.white.20');
    -webkit-text-fill-color: transparent;
    $c: &;

    @apply .w-full .inline-block .font-black .whitespace-no-wrap;
}
