.c-hero {
    $c: &;

    @apply .text-0 .relative;

    &__top {
        min-height: 280px;

        @apply .relative .flex;

        @screen md {
            min-height: 320px;
        }

        @screen lg {
            min-height: 400px;
        }

        @screen xl {
            min-height: 660px;
        }
    }

    &__sub-title {
        @apply .inline-block;
    }

    &__title {
        @apply .text-white;
    }

    &__sub-title,
    &__text {
        @apply .text-grey-light;
    }

    &.has-picture {
        @apply .bg-white;

        #{$c} {
            &__top {
                &:before {
                    height: 200px;

                    content: '';

                    background: linear-gradient(180deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);

                    @apply .w-full .absolute top-0 .right-0 .left-0 .z-10;
                }
            }

            &__picture {
                @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .z-0 .w-full;
            }

            &__media {
                &:before {
                    content: ''; background-color: rgba(0, 0, 0, 0.3);

                    @apply .absolute .inset-0 .z-10;
                }
            }
        }
    }

    &--alt {
        #{$c} {
            &__top {
                min-height: 280px;

                @screen md {
                    min-height: 320px;
                }

                @screen lg {
                    min-height: 400px;
                }

                @screen xl {
                    min-height: 480px;
                }
            }

            &__bottom {
                @apply .relative;
            }

            &__body {
                @apply .relative;
            }

            &__body-wrapper {
                transform: translateY(-20px);

                @apply .pb-8;

                @screen md {
                    transform: translateY(-24px);
                }

                @screen lg {
                    transform: translateY(-32px);
                }

                @screen xl {
                    transform: translateY(-64px);
                }
            }
        }
    }
}
