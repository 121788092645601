.c-nav {
    $c: &;

    @apply .text-0;

    &__list {
    }

    &__item {
    }

    &__link {
    }

    &__label,
    &__label-text,
    &__label-icon,
    &__sub-label {
        @apply .inline-block .transition-all .duration-300 .ease-in-out;
    }

    &__label {
    }

    &__label-text {
    }

    &__label-icon {
    }

    &--primary {
        @apply .relative;

        #{$c} {
            &__list {
                @apply .flex .flex-col;

                @screen lg {
                    @apply .flex-row;
                }
            }

            &__item {
                @apply .relative .pb-5 .mb-5;

                @screen md {
                    @apply .pb-8 .mb-8;
                }

                @screen lg {
                    @apply .relative .pr-5 .mr-5 .pb-0 .mb-0;
                }

                &:after {
                    content: '';
                    transform: translate(-50%, 50%);

                    @apply .rounded-full .absolute .bottom-0 .w-0.75 .h-0.75;

                    @screen lg {
                        top: 50%;

                        transform: translate(50%, -50%);

                        @apply .right-0 .bottom-auto;
                    }
                }

                &:last-child {
                    @apply .pb-0 .mb-0;

                    @screen lg {
                        @apply .pr-0 .mr-0;
                    }

                    &:after {
                        @apply .hidden;
                    }
                }

                &.is-active {
                    #{$c} {
                        &__item-wrapper {
                            @screen lg {
                                &:after {
                                    content: '';

                                    @apply .w-full .h-px .absolute .right-0 .bottom-0 .left-0 .bg-white .-mb-px;
                                }
                            }
                        }

                        &__label-text,
                        &__label-icon {
                            @screen lg {
                                @apply .text-white;
                            }
                        }
                    }
                }
            }

            &__item-wrapper {
                @apply .relative;

                @screen lg {
                    @apply .py-13;
                }
            }

            &__item-inner {
                @apply .relative;

                &.is-toggled {
                    #{$c} {
                        &__sub {
                            max-height: 500px;

                            @apply .overflow-visible;

                            @screen lg {
                                @apply .hidden;
                            }
                        }

                        &__label-icon {
                            transform: rotate(180deg);
                        }
                    }
                }

                @screen lg {
                    &.has-sub-nav {
                        &:after {
                            top: 100%;
                            left: 50%;

                            width: 0;
                            height: 0;

                            content: '';
                            transform: translateX(-50%);

                            border-right: 10px solid transparent;
                            border-bottom: 10px solid theme('colors.white.default');
                            border-left: 10px solid transparent;

                            @apply .absolute .pt-5.5 .opacity-0 .transition-all .duration-300 .ease-in-out;
                        }

                        &:hover,
                        &:active,
                        &.has-hover {
                            &:after {
                                @apply .opacity-100 .pt-3;
                            }
                        }
                    }
                }
            }

            &__link {
            }

            &__label {
                @apply .flex .items-center;
            }

            &__label-text,
            &__label-icon {
                @apply .text-white;
            }

            &__label-text {
                @apply .text-xl .font-bold .font-primary .leading-none;

                @screen md {
                    @apply .text-2xl;
                }

                @screen lg {
                    @apply .text-base;
                }
            }

            &__label-icon {
                @apply .ml-1.25;
            }

            &__sub {
                max-height: 0;

                transition-timing-function: cubic-bezier(.39, .58, .57, 1);

                @apply .overflow-hidden .transition-all .duration-500;

                @screen lg {
                    top: 100%;

                    width: 856px;

                    transform: translateY(-52px);

                    @apply .absolute .right-0 .-z-1 .opacity-0 .pt-8 .overflow-visible .duration-300 .ease-in-out;

                    &.is-active {
                        @apply .z-10 .opacity-100 .pt-5.5;
                    }
                }
            }

            &__sub-list {
                @apply .-mb-4 .pt-4;

                @screen lg {
                    @apply .flex .flex-wrap .justify-end .mb-0 .pt-0 .border-r .border-grey-light;
                }
            }

            &__sub-item {
                @apply .pb-4;

                @screen lg {
                    @apply .w-1/5 .pb-0;
                }
            }

            &__sub-link {
                @apply .relative .flex .items-center .justify-center .text-center;

                @screen lg {
                    height: 100px;

                    @apply .bg-white .border-l .border-b .border-grey-light .p-5;

                    &:after {
                        content: '';
                        transform: translateY(100%);

                        @apply .absolute .bottom-0 .right-0 .left-0 .h-0.5 .w-full .opacity-0 .transition-all .duration-300 .ease-in-out;
                    }

                    &:hover,
                    &:active {
                        @apply .bg-grey-lighter;

                        &:after {
                            transform: translateY(1px);

                            @apply .opacity-100;
                        }
                    }
                }
            }

            &__sub-label {
                @apply .text-base .leading-tighter .text-white .font-normal;

                @screen md {
                    @apply .text-xl;
                }

                @screen lg {
                    @apply .text-secondary .text-base .font-bold;
                }
            }
        }
    }

    &--breadcrumb {
        #{$c} {
            &__list {
                @apply .flex .flex-wrap .-mb-2;
            }

            &__item {
                @apply .relative .pr-3 .mr-1 .pb-2;

                &:after {
                    content: '/';

                    @apply .absolute .top-0 .right-0 .inline-block .font-primary .text-base .leading-tighter .font-bold .text-grey-light;
                }

                &:last-child {
                    @apply .pr-0 .mr-0;

                    &:after {
                        @apply .hidden;
                    }
                }
            }

            &__label {
            }

            &__label-text {
                @apply .text-base .font-bold .leading-tighter .text-grey-light;
            }

            &__label-icon {
            }
        }
    }

    &--footer {
        #{$c} {
            &__list {
                @apply .flex .flex-wrap .-mb-2 .justify-center;
            }

            &__item {
                @apply .pb-2;

                &:last-child {
                    #{$c} {
                        &__item-wrapper {
                            @apply .pr-0 .mr-0;

                            &:after {
                                @apply .hidden;
                            }
                        }
                    }
                }
            }

            &__item-wrapper {
                @apply .relative .pr-2.5 .mr-2.5;

                @screen md {
                    @apply .pr-5 .mr-5;
                }

                &:after {
                    top: 50%;

                    content: '';
                    transform: translate(50%, -50%);

                    @apply .bg-white .opacity-50 .rounded-full .absolute .right-0 .w-0.75 .h-0.75;
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label-text {
                            @apply .opacity-75;
                        }
                    }
                }
            }

            &__label {
            }

            &__label-text {
                @apply .text-base .font-bold .text-white .leading-tighter;
            }

            &__label-icon {
            }
        }
    }

    &--legal {
        #{$c} {
            &__list {
                @apply .flex .flex-wrap .-ml-5 .-mb-2 .justify-center;
            }

            &__item {
                @apply .pl-5 .pb-2;
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label-text {
                            @apply .text-white;
                        }
                    }
                }
            }

            &__label {
            }

            &__label-text {
                @apply .text-base .font-normal .text-grey-light .leading-tighter;
            }

            &__label-icon {
            }
        }
    }
}
