@charset "UTF-8";
/* purgecss start ignore */
@tailwind base;
@tailwind components;
* {
  min-width: 0;
  min-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-variant-ligatures: none;
  @apply .antialiased .scrolling-touch;
}

html,
body {
  @apply .h-full;
}

html {
  @apply .overflow-x-hidden;
}

b,
strong {
  @apply .font-bold;
}

::selection {
  @apply .text-white;
}

.grecaptcha-badge {
  @apply .invisible;
}

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms;
}

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0;
}

body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
  transition-delay: 50ms;
}

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms;
}

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0;
}

body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
  transition-delay: 100ms;
}

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms;
}

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0;
}

body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
  transition-delay: 150ms;
}

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms;
}

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0;
}

body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
  transition-delay: 200ms;
}

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms;
}

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0;
}

body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
  transition-delay: 250ms;
}

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms;
}

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0;
}

body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
  transition-delay: 300ms;
}

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms;
}

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0;
}

body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
  transition-delay: 350ms;
}

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms;
}

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0;
}

body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
  transition-delay: 400ms;
}

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms;
}

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0;
}

body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
  transition-delay: 450ms;
}

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms;
}

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0;
}

body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
  transition-delay: 500ms;
}

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms;
}

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0;
}

body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
  transition-delay: 550ms;
}

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms;
}

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0;
}

body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
  transition-delay: 600ms;
}

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms;
}

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0;
}

body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
  transition-delay: 650ms;
}

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms;
}

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0;
}

body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
  transition-delay: 700ms;
}

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms;
}

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0;
}

body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
  transition-delay: 750ms;
}

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms;
}

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0;
}

body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
  transition-delay: 800ms;
}

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms;
}

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0;
}

body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
  transition-delay: 850ms;
}

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms;
}

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0;
}

body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
  transition-delay: 900ms;
}

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms;
}

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0;
}

body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
  transition-delay: 950ms;
}

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms;
}

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0;
}

body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
  transition-delay: 1000ms;
}

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms;
}

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0;
}

body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
  transition-delay: 1050ms;
}

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms;
}

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0;
}

body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
  transition-delay: 1100ms;
}

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms;
}

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0;
}

body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
  transition-delay: 1150ms;
}

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms;
}

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0;
}

body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
  transition-delay: 1200ms;
}

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms;
}

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0;
}

body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
  transition-delay: 1250ms;
}

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms;
}

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0;
}

body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
  transition-delay: 1300ms;
}

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms;
}

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0;
}

body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
  transition-delay: 1350ms;
}

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms;
}

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0;
}

body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
  transition-delay: 1400ms;
}

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms;
}

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0;
}

body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
  transition-delay: 1450ms;
}

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms;
}

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0;
}

body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
  transition-delay: 1500ms;
}

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms;
}

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0;
}

body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
  transition-delay: 1550ms;
}

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms;
}

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0;
}

body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
  transition-delay: 1600ms;
}

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms;
}

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0;
}

body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
  transition-delay: 1650ms;
}

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms;
}

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0;
}

body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
  transition-delay: 1700ms;
}

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms;
}

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0;
}

body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
  transition-delay: 1750ms;
}

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms;
}

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0;
}

body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
  transition-delay: 1800ms;
}

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms;
}

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0;
}

body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
  transition-delay: 1850ms;
}

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms;
}

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0;
}

body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
  transition-delay: 1900ms;
}

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms;
}

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0;
}

body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
  transition-delay: 1950ms;
}

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms;
}

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0;
}

body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
  transition-delay: 2000ms;
}

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms;
}

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0;
}

body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
  transition-delay: 2050ms;
}

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms;
}

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0;
}

body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
  transition-delay: 2100ms;
}

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms;
}

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0;
}

body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
  transition-delay: 2150ms;
}

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms;
}

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0;
}

body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
  transition-delay: 2200ms;
}

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms;
}

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0;
}

body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
  transition-delay: 2250ms;
}

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms;
}

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0;
}

body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
  transition-delay: 2300ms;
}

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms;
}

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0;
}

body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
  transition-delay: 2350ms;
}

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms;
}

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0;
}

body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
  transition-delay: 2400ms;
}

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms;
}

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0;
}

body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
  transition-delay: 2450ms;
}

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms;
}

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0;
}

body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
  transition-delay: 2500ms;
}

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms;
}

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0;
}

body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
  transition-delay: 2550ms;
}

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms;
}

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0;
}

body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
  transition-delay: 2600ms;
}

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms;
}

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0;
}

body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
  transition-delay: 2650ms;
}

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms;
}

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0;
}

body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
  transition-delay: 2700ms;
}

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms;
}

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0;
}

body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
  transition-delay: 2750ms;
}

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms;
}

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0;
}

body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
  transition-delay: 2800ms;
}

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms;
}

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0;
}

body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
  transition-delay: 2850ms;
}

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms;
}

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0;
}

body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
  transition-delay: 2900ms;
}

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms;
}

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0;
}

body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
  transition-delay: 2950ms;
}

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms;
}

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0;
}

body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
  transition-delay: 3000ms;
}

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^='fade'][data-aos^='fade'].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0);
}

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0);
}

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0);
}

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0);
}

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^='zoom'][data-aos^='zoom'].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}

[data-aos='zoom-in'] {
  transform: scale(0.6);
}

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos='zoom-out'] {
  transform: scale(1.2);
}

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform;
}

[data-aos^='slide'][data-aos^='slide'].aos-animate {
  transform: translate3d(0, 0, 0);
}

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0);
}

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0);
}

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0);
}

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos='flip-left'].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos='flip-right'].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos='flip-up'].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos='flip-down'].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 1;
}

.hamburger.is-active:hover {
  opacity: 1;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: theme("colors.white.default");
}

.hamburger-box {
  width: 26px;
  height: 18px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 26px;
  height: 2px;
  background-color: theme("colors.white.default");
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -8px;
}

.hamburger-inner::after {
  bottom: -8px;
}

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 52px;
}

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}

.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 52px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 52px;
}

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}

.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 52px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 52px;
}

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}

.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 52px;
}

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-5.2px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-5.2px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(5.2px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(5.2px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-5.2px, -6.5px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-5.2px, 6.5px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(5.2px, -6.5px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(5.2px, 6.5px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 8px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 16px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r .hamburger-inner::before {
  top: 8px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic-r .hamburger-inner::after {
  top: 16px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(270deg);
  transition-delay: 0.075s;
}

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::after {
  top: 8px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -52px;
  top: -52px;
  transform: translate3d(52px, 52px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -52px;
  top: -52px;
  transform: translate3d(-52px, 52px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::after {
  top: 8px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -52px;
  top: 52px;
  transform: translate3d(52px, -52px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -52px;
  top: 52px;
  transform: translate3d(-52px, -52px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 1px;
}

.hamburger--slider .hamburger-inner::before {
  top: 8px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
  top: 16px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-3.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 1px;
}

.hamburger--slider-r .hamburger-inner::before {
  top: 8px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider-r .hamburger-inner::after {
  top: 16px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(3.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(90deg);
}

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 1px;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 8px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/images/vendor/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

body {
  @apply .text-grey .font-primary .font-normal .text-lg .leading-snug .bg-white;
}

@screen md {
  body {
    @apply .text-xl;
  }
}

button {
  @apply .transition-all .duration-300 .ease-in-out;
}

button:focus {
  outline: none;
}

button:disabled, button[disabled] {
  @apply .cursor-not-allowed;
}

.h0,
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply .font-primary .font-black .text-secondary .leading-tighter;
}

.h0 strong,
h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong {
  @apply .font-black;
}

.h0 {
  @apply .leading-tighter;
  @screen md {}  @screen lg {}
}

@screen xl {
  .h0 {
    @apply .text-10xl;
  }
}

h1,
.h1 {
  @apply .text-3xl;
}

@screen md {
  h1,
  .h1 {
    @apply .text-4xl;
  }
}

@screen lg {
  h1,
  .h1 {
    @apply .text-5xl;
  }
}

h2,
.h2 {
  @apply .text-2xl;
}

@screen md {
  h2,
  .h2 {
    @apply .text-3xl;
  }
}

@screen lg {
  h2,
  .h2 {
    @apply .text-4xl;
  }
}

h3,
.h3 {
  @apply .text-xxl .leading-tight;
}

@screen md {
  h3,
  .h3 {
    @apply .text-2xl;
  }
}

@screen lg {
  h3,
  .h3 {
    @apply .text-3xl;
  }
}

h4,
.h4 {
  @apply .text-xl .leading-snug;
}

@screen md {
  h4,
  .h4 {
    @apply .text-xxl;
  }
}

@screen lg {
  h4,
  .h4 {
    @apply .text-2xl;
  }
}

h5,
.h5 {
  @apply .text-lg .leading-snug;
}

@screen md {
  h5,
  .h5 {
    @apply .text-xl;
  }
}

h6,
.h6 {
  @apply .text-base .leading-snug;
}

@screen md {
  h6,
  .h6 {
    @apply .text-lg;
  }
}

input,
textarea {
  @apply .rounded-none .p-0 .appearance-none;
}

input:focus,
textarea:focus {
  outline: none;
}

input::placeholder,
textarea::placeholder {
  @apply .opacity-100;
}

textarea {
  @apply .resize-none;
}

select {
  @apply .appearance-none .rounded-none;
}

select:focus {
  outline: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  @apply .appearance-none;
}

a {
  outline: none;
  @apply .relative .inline-block .no-underline .transition-all .duration-300 .ease-in-out;
}

a:focus {
  outline: none;
}

.c-become-a-partner {
  @apply .text-0 .relative .flex;
}

@screen md {
  .c-become-a-partner {
    min-height: 540px;
  }
}

.c-button {
  @apply .relative .text-0 .py-4.75 .px-6 .border-2 .border-transparent .inline-flex .items-center .justify-center .w-full .transition-all .duration-300 .ease-in-out;
}

@screen md {
  .c-button {
    @apply .w-auto;
  }
}

.c-button__label, .c-button__icon {
  @apply .inline-block .transition-all .duration-300 .ease-in-out;
}

.c-button__label {
  @apply .text-base .leading-tighter .font-bold;
}

.c-button--primary .c-button__label, .c-button--primary .c-button__icon {
  @apply .text-white .relative .z-10;
}

.c-button--primary:after {
  content: '';
  transform: scaleY(0);
  transform-origin: bottom;
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .bg-secondary .-m-0.5 .transition-all .duration-300 .ease-in-out;
}

.c-button--primary:hover, .c-button--primary:active {
  @apply .bg-secondary;
}

.c-button--primary:hover:after, .c-button--primary:active:after {
  transform: scaleY(1);
}

.c-button--secondary {
  @apply .bg-transparent;
}

.c-button--secondary .c-button__label, .c-button--secondary .c-button__icon {
  @apply .text-secondary;
}

.c-button--secondary:hover, .c-button--secondary:active {
  @apply .border-secondary;
}

.c-button--tertiary {
  @apply .bg-transparent .border-white-30;
}

.c-button--tertiary .c-button__label, .c-button--tertiary .c-button__icon {
  @apply .text-white;
}

.c-button--tertiary:hover, .c-button--tertiary:active {
  @apply .border-white;
}

.c-button.has-icon-after .c-button__icon {
  @apply .ml-2.5;
}

.c-button.has-icon-before .c-button__icon {
  @apply .mr-2.5;
}

.c-button.has-spinning-icon:hover .c-button__icon, .c-button.has-spinning-icon:active .c-button__icon {
  transform: rotate(360deg);
}

.c-button.is-small {
  @apply .py-3 .px-4;
}

.c-button.is-small .c-button__label {
  @apply .text-sm;
}

.c-container {
  @apply .w-full .max-w-8xl .px-6 .mx-auto;
}

.c-content__section--alt {
  @apply .bg-grey-lighter;
}

.c-cookie-consent {
  z-index: -1;
  transform: translateY(100%);
  @apply .fixed .right-0 .bottom-0 .left-0 .bg-grey-lighter .border .border-grey-light .mt-6 .mr-6 .ml-6 .text-center .p-6 .transition-all .duration-500 .ease-in-out;
}

@screen md {
  .c-cookie-consent {
    @apply .left-auto .max-w-xs;
  }
}

.c-cookie-consent__message {
  @apply .mb-4;
}

.c-cookie-consent.in-view {
  z-index: 9999;
  transform: translateY(0);
  @apply .mb-6;
}

.c-cta {
  @apply .text-0 .py-28;
}

.c-cta__inner {
  @apply .max-w-xl .mx-auto .text-center;
}

.c-cta__title, .c-cta__text {
  @apply .text-white;
}

.c-footer {
  @apply .text-0 .bg-secondary-dark .py-7.5;
}

.c-footer a {
  @apply .text-base .leading-tighter .text-grey-light;
}

.c-footer a:hover, .c-footer a:active {
  @apply .text-white;
}

.c-form-field {
  @apply .text-0;
}

.c-form-field__wrapper {
  @apply .relative;
}

.c-form-field__label, .c-form-field__input {
  @apply .text-base .font-bold .leading-tighter .cursor-text .px-5 .py-4.5 .transition-all .duration-300 .ease-in-out;
}

.c-form-field__label {
  @apply .absolute .top-0 .bottom-0 .inline-block .left-0 .text-grey .text-right;
}

.c-form-field__label.is-required:after {
  content: '*';
  @apply .font-primary .text-base .leading-tight .font-bold .ml-1 .transition-all .duration-300 .ease-in-out;
}

.c-form-field__input {
  @apply .text-secondary .border .border-grey-light .w-full;
}

.c-form-field__input:focus, .c-form-field__input:not([value='']):not(:focus) {
  @apply .border-grey;
}

.c-form-field__input:focus + .c-form-field__label:nth-child(n), .c-form-field__input:not([value='']):not(:focus) + .c-form-field__label:nth-child(n) {
  transform: translateY(-12px);
  @apply .pb-0 .bottom-auto .text-xxs;
}

.c-form-field__input:focus + .c-form-field__label:nth-child(n).is-required:after, .c-form-field__input:not([value='']):not(:focus) + .c-form-field__label:nth-child(n).is-required:after {
  @apply .text-xxs .ml-0.5;
}

.c-form-field.has-errors .c-form-field__input {
  @apply .border-error;
}

.c-form {
  @apply .relative .text-0;
}

.c-form__rows {
  @apply .-mb-4;
}

.c-form__row {
  @apply .pb-4;
}

.c-form__columns {
  @apply .-mb-4 .-ml-4;
}

@screen lg {
  .c-form__columns {
    @apply .flex;
  }
}

.c-form__column {
  @apply .flex-1 .pb-4 .pl-4;
}

.c-form[data-type="newsletter"] .c-form__columns > *:nth-child(1) {
  flex-basis: 70%;
}

.c-form[data-type="newsletter"] .c-form__columns > *:nth-child(2) {
  flex-basis: 30%;
}

.c-form[data-type="newsletter"] .c-form-field--email input {
  @apply .bg-transparent .border-2 .border-kbrv-secondary .h-15;
}

.c-form[data-type="newsletter"] .c-form-field--email label {
  top: auto;
  bottom: 1px;
}

.c-form[data-type="newsletter"] .c-button {
  @apply .bg-kbrv-secondary;
}

.c-form[data-type="newsletter"] .c-button__label {
  @apply .text-white;
}

.c-form[data-type="newsletter"] .c-button__icon {
  @apply .text-white;
}

.c-funding {
  @apply .text-0 .relative .bg-white .w-full;
}

.c-funding__picture {
  @apply .h-full;
}

.c-funding__body {
  @apply .p-12;
}

@screen md {
  .c-funding__body {
    @apply .px-0 .py-16;
  }
}

@screen lg {
  .c-funding__body {
    @apply .py-20;
  }
}

@screen md {
  .c-funding:after {
    left: 100%;
    content: '';
    @apply .absolute .bg-white .top-0 .right-0 .bottom-0 .w-full .transition-all .duration-300 .ease-in-out;
  }
}

@screen md {
  .c-funding:hover, .c-funding:active {
    @apply .bg-grey-lighter;
  }
  .c-funding:hover:after, .c-funding:active:after {
    @apply .bg-grey-lighter;
  }
}

.c-header {
  @apply .relative .text-0;
}

.c-header__inner {
  @apply .border-b .border-white-20;
}

.c-hero {
  @apply .text-0 .relative;
}

.c-hero__top {
  min-height: 280px;
  @apply .relative .flex;
}

@screen md {
  .c-hero__top {
    min-height: 320px;
  }
}

@screen lg {
  .c-hero__top {
    min-height: 400px;
  }
}

@screen xl {
  .c-hero__top {
    min-height: 660px;
  }
}

.c-hero__sub-title {
  @apply .inline-block;
}

.c-hero__title {
  @apply .text-white;
}

.c-hero__sub-title, .c-hero__text {
  @apply .text-grey-light;
}

.c-hero.has-picture {
  @apply .bg-white;
}

.c-hero.has-picture .c-hero__top:before {
  height: 200px;
  content: '';
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  @apply .w-full .absolute top-0 .right-0 .left-0 .z-10;
}

.c-hero.has-picture .c-hero__picture {
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .z-0 .w-full;
}

.c-hero.has-picture .c-hero__media:before {
  content: '';
  background-color: rgba(0, 0, 0, 0.3);
  @apply .absolute .inset-0 .z-10;
}

.c-hero--alt .c-hero__top {
  min-height: 280px;
}

@screen md {
  .c-hero--alt .c-hero__top {
    min-height: 320px;
  }
}

@screen lg {
  .c-hero--alt .c-hero__top {
    min-height: 400px;
  }
}

@screen xl {
  .c-hero--alt .c-hero__top {
    min-height: 480px;
  }
}

.c-hero--alt .c-hero__bottom {
  @apply .relative;
}

.c-hero--alt .c-hero__body {
  @apply .relative;
}

.c-hero--alt .c-hero__body-wrapper {
  transform: translateY(-20px);
  @apply .pb-8;
}

@screen md {
  .c-hero--alt .c-hero__body-wrapper {
    transform: translateY(-24px);
  }
}

@screen lg {
  .c-hero--alt .c-hero__body-wrapper {
    transform: translateY(-32px);
  }
}

@screen xl {
  .c-hero--alt .c-hero__body-wrapper {
    transform: translateY(-64px);
  }
}

.c-highlight {
  @apply .text-0 .relative .bg-white .w-full;
}

.c-highlight__picture {
  @apply .h-full;
}

.c-highlight__body {
  @apply .p-12;
}

@screen md {
  .c-highlight__body {
    @apply .p-0;
  }
}

.c-icon {
  @apply .fill-current .transition-all .duration-300 .ease-in-out;
}

.c-icon--arrow-down {
  width: 7px;
  height: 5px;
}

.c-icon--arrow-down--alt {
  width: 12px;
  height: 8px;
}

.c-icon--arrow-right {
  width: 5px;
  height: 7px;
}

.c-icon--long-arrow-right {
  width: 24px;
  height: 10px;
}

.c-icon--plus {
  width: 10px;
  height: 10px;
}

.c-icon--plus--alt {
  width: 17px;
  height: 17px;
}

.c-icon--download {
  width: 16px;
  height: 17px;
}

.c-icon--close {
  width: 20px;
  height: 20px;
}

.c-icon--twitter {
  width: 20px;
  height: 20px;
}

.c-icon--instagram {
  width: 20px;
  height: 20px;
}

.c-icon--linkedin {
  width: 20px;
  height: 20px;
}

.c-icon--pause {
  width: 19px;
  height: 21px;
}

.c-icon--play {
  width: 17px;
  height: 21px;
  padding-left: 2px;
}

.c-link {
  @apply .text-0 .relative .inline-flex .items-center;
}

.c-link__label, .c-link__icon {
  @apply .inline-block .font-bold .text-base .leading-tighter .transition-all .duration-300 .ease-in-out;
}

.c-link--primary {
  @apply .pb-2;
}

.c-link--primary .c-link__label {
  @apply .text-secondary;
}

.c-link--primary:after {
  content: '';
  @apply .absolute .left-0 .bottom-0 .w-5 .h-0.5 .transition-all .duration-300 .ease-in-out;
}

.c-link--primary:hover:after, .c-link--primary:active:after, .c-link--primary.hover:after {
  @apply .w-full;
}

.c-link--secondary:hover .c-link__label,
.c-link--secondary:hover .c-link__icon .c-icon, .c-link--secondary:active .c-link__label,
.c-link--secondary:active .c-link__icon .c-icon, .c-link--secondary.hover .c-link__label,
.c-link--secondary.hover .c-link__icon .c-icon {
  @apply .text-secondary;
}

.c-link--tertiary {
  @apply .border-b .border-secondary;
}

.c-link--tertiary .c-link__label,
.c-link--tertiary .c-link__icon .c-icon {
  @apply .text-secondary .font-normal .text-lg .leading-snug;
}

@screen md {
  .c-link--tertiary .c-link__label,
  .c-link--tertiary .c-link__icon .c-icon {
    @apply .text-xl;
  }
}

.c-link--quaternary .c-link__label,
.c-link--quaternary .c-link__icon .c-icon {
  @apply .text-secondary .duration-500;
}

.c-link--quaternary:hover .c-link__label,
.c-link--quaternary:hover .c-link__icon .c-icon, .c-link--quaternary:active .c-link__label,
.c-link--quaternary:active .c-link__icon .c-icon, .c-link--quaternary.hover .c-link__label,
.c-link--quaternary.hover .c-link__icon .c-icon {
  @apply .text-white;
}

.c-link.has-icon-after .c-link__icon {
  @apply .ml-2.5;
}

.c-link.has-icon-before .c-link__icon {
  @apply .mr-2.5;
}

.c-logo {
  @apply .text-0 .relative .z-50;
}

.c-mobile-nav {
  z-index: -1;
  @apply .bg-secondary-dark .opacity-0 .fixed .w-full .h-full .top-0 .transition-opacity .duration-200 .ease-linear .overflow-y-scroll;
}

.c-mobile-nav.is-active {
  @apply .transition-all .z-50 .opacity-100;
}

@screen lg {
  .c-mobile-nav {
    @apply .hidden;
  }
}

.c-nav {
  @apply .text-0;
}

.c-nav__label, .c-nav__label-text, .c-nav__label-icon, .c-nav__sub-label {
  @apply .inline-block .transition-all .duration-300 .ease-in-out;
}

.c-nav--primary {
  @apply .relative;
}

.c-nav--primary .c-nav__list {
  @apply .flex .flex-col;
}

@screen lg {
  .c-nav--primary .c-nav__list {
    @apply .flex-row;
  }
}

.c-nav--primary .c-nav__item {
  @apply .relative .pb-5 .mb-5;
}

@screen md {
  .c-nav--primary .c-nav__item {
    @apply .pb-8 .mb-8;
  }
}

@screen lg {
  .c-nav--primary .c-nav__item {
    @apply .relative .pr-5 .mr-5 .pb-0 .mb-0;
  }
}

.c-nav--primary .c-nav__item:after {
  content: '';
  transform: translate(-50%, 50%);
  @apply .rounded-full .absolute .bottom-0 .w-0.75 .h-0.75;
}

@screen lg {
  .c-nav--primary .c-nav__item:after {
    top: 50%;
    transform: translate(50%, -50%);
    @apply .right-0 .bottom-auto;
  }
}

.c-nav--primary .c-nav__item:last-child {
  @apply .pb-0 .mb-0;
}

@screen lg {
  .c-nav--primary .c-nav__item:last-child {
    @apply .pr-0 .mr-0;
  }
}

.c-nav--primary .c-nav__item:last-child:after {
  @apply .hidden;
}

@screen lg {
  .c-nav--primary .c-nav__item.is-active .c-nav__item-wrapper:after {
    content: '';
    @apply .w-full .h-px .absolute .right-0 .bottom-0 .left-0 .bg-white .-mb-px;
  }
}

@screen lg {
  .c-nav--primary .c-nav__item.is-active .c-nav__label-text, .c-nav--primary .c-nav__item.is-active .c-nav__label-icon {
    @apply .text-white;
  }
}

.c-nav--primary .c-nav__item-wrapper {
  @apply .relative;
}

@screen lg {
  .c-nav--primary .c-nav__item-wrapper {
    @apply .py-13;
  }
}

.c-nav--primary .c-nav__item-inner {
  @apply .relative;
}

.c-nav--primary .c-nav__item-inner.is-toggled .c-nav__sub {
  max-height: 500px;
  @apply .overflow-visible;
}

@screen lg {
  .c-nav--primary .c-nav__item-inner.is-toggled .c-nav__sub {
    @apply .hidden;
  }
}

.c-nav--primary .c-nav__item-inner.is-toggled .c-nav__label-icon {
  transform: rotate(180deg);
}

@screen lg {
  .c-nav--primary .c-nav__item-inner.has-sub-nav:after {
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    content: '';
    transform: translateX(-50%);
    border-right: 10px solid transparent;
    border-bottom: 10px solid theme("colors.white.default");
    border-left: 10px solid transparent;
    @apply .absolute .pt-5.5 .opacity-0 .transition-all .duration-300 .ease-in-out;
  }
  .c-nav--primary .c-nav__item-inner.has-sub-nav:hover:after, .c-nav--primary .c-nav__item-inner.has-sub-nav:active:after, .c-nav--primary .c-nav__item-inner.has-sub-nav.has-hover:after {
    @apply .opacity-100 .pt-3;
  }
}

.c-nav--primary .c-nav__label {
  @apply .flex .items-center;
}

.c-nav--primary .c-nav__label-text, .c-nav--primary .c-nav__label-icon {
  @apply .text-white;
}

.c-nav--primary .c-nav__label-text {
  @apply .text-xl .font-bold .font-primary .leading-none;
}

@screen md {
  .c-nav--primary .c-nav__label-text {
    @apply .text-2xl;
  }
}

@screen lg {
  .c-nav--primary .c-nav__label-text {
    @apply .text-base;
  }
}

.c-nav--primary .c-nav__label-icon {
  @apply .ml-1.25;
}

.c-nav--primary .c-nav__sub {
  max-height: 0;
  transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
  @apply .overflow-hidden .transition-all .duration-500;
}

@screen lg {
  .c-nav--primary .c-nav__sub {
    top: 100%;
    width: 856px;
    transform: translateY(-52px);
    @apply .absolute .right-0 .-z-1 .opacity-0 .pt-8 .overflow-visible .duration-300 .ease-in-out;
  }
  .c-nav--primary .c-nav__sub.is-active {
    @apply .z-10 .opacity-100 .pt-5.5;
  }
}

.c-nav--primary .c-nav__sub-list {
  @apply .-mb-4 .pt-4;
}

@screen lg {
  .c-nav--primary .c-nav__sub-list {
    @apply .flex .flex-wrap .justify-end .mb-0 .pt-0 .border-r .border-grey-light;
  }
}

.c-nav--primary .c-nav__sub-item {
  @apply .pb-4;
}

@screen lg {
  .c-nav--primary .c-nav__sub-item {
    @apply .w-1/5 .pb-0;
  }
}

.c-nav--primary .c-nav__sub-link {
  @apply .relative .flex .items-center .justify-center .text-center;
}

@screen lg {
  .c-nav--primary .c-nav__sub-link {
    height: 100px;
    @apply .bg-white .border-l .border-b .border-grey-light .p-5;
  }
  .c-nav--primary .c-nav__sub-link:after {
    content: '';
    transform: translateY(100%);
    @apply .absolute .bottom-0 .right-0 .left-0 .h-0.5 .w-full .opacity-0 .transition-all .duration-300 .ease-in-out;
  }
  .c-nav--primary .c-nav__sub-link:hover, .c-nav--primary .c-nav__sub-link:active {
    @apply .bg-grey-lighter;
  }
  .c-nav--primary .c-nav__sub-link:hover:after, .c-nav--primary .c-nav__sub-link:active:after {
    transform: translateY(1px);
    @apply .opacity-100;
  }
}

.c-nav--primary .c-nav__sub-label {
  @apply .text-base .leading-tighter .text-white .font-normal;
}

@screen md {
  .c-nav--primary .c-nav__sub-label {
    @apply .text-xl;
  }
}

@screen lg {
  .c-nav--primary .c-nav__sub-label {
    @apply .text-secondary .text-base .font-bold;
  }
}

.c-nav--breadcrumb .c-nav__list {
  @apply .flex .flex-wrap .-mb-2;
}

.c-nav--breadcrumb .c-nav__item {
  @apply .relative .pr-3 .mr-1 .pb-2;
}

.c-nav--breadcrumb .c-nav__item:after {
  content: '/';
  @apply .absolute .top-0 .right-0 .inline-block .font-primary .text-base .leading-tighter .font-bold .text-grey-light;
}

.c-nav--breadcrumb .c-nav__item:last-child {
  @apply .pr-0 .mr-0;
}

.c-nav--breadcrumb .c-nav__item:last-child:after {
  @apply .hidden;
}

.c-nav--breadcrumb .c-nav__label-text {
  @apply .text-base .font-bold .leading-tighter .text-grey-light;
}

.c-nav--footer .c-nav__list {
  @apply .flex .flex-wrap .-mb-2 .justify-center;
}

.c-nav--footer .c-nav__item {
  @apply .pb-2;
}

.c-nav--footer .c-nav__item:last-child .c-nav__item-wrapper {
  @apply .pr-0 .mr-0;
}

.c-nav--footer .c-nav__item:last-child .c-nav__item-wrapper:after {
  @apply .hidden;
}

.c-nav--footer .c-nav__item-wrapper {
  @apply .relative .pr-2.5 .mr-2.5;
}

@screen md {
  .c-nav--footer .c-nav__item-wrapper {
    @apply .pr-5 .mr-5;
  }
}

.c-nav--footer .c-nav__item-wrapper:after {
  top: 50%;
  content: '';
  transform: translate(50%, -50%);
  @apply .bg-white .opacity-50 .rounded-full .absolute .right-0 .w-0.75 .h-0.75;
}

.c-nav--footer .c-nav__link:hover .c-nav__label-text, .c-nav--footer .c-nav__link:active .c-nav__label-text {
  @apply .opacity-75;
}

.c-nav--footer .c-nav__label-text {
  @apply .text-base .font-bold .text-white .leading-tighter;
}

.c-nav--legal .c-nav__list {
  @apply .flex .flex-wrap .-ml-5 .-mb-2 .justify-center;
}

.c-nav--legal .c-nav__item {
  @apply .pl-5 .pb-2;
}

.c-nav--legal .c-nav__link:hover .c-nav__label-text, .c-nav--legal .c-nav__link:active .c-nav__label-text {
  @apply .text-white;
}

.c-nav--legal .c-nav__label-text {
  @apply .text-base .font-normal .text-grey-light .leading-tighter;
}

.c-outlined-title {
  font-size: 190px;
  line-height: 160px;
  -webkit-text-stroke: 1px theme("colors.white.20");
  -webkit-text-fill-color: transparent;
  @apply .w-full .inline-block .font-black .whitespace-no-wrap;
}

.c-page {
  @apply .relative;
}

.c-page.mobile-nav-open {
  @apply .fixed .w-full .h-full .overflow-hidden;
}

.c-page--contact .c-content {
  @apply .overflow-hidden;
}

.c-overlay:after {
  content: '';
  @apply .bg-secondary .-z-1 .opacity-0 .absolute .top-0 .right-0 .bottom-0 .left-0 .transition-all .duration-300 .ease-in-out;
}

.c-overlay.is-active .c-header {
  @apply .z-50;
}

.c-overlay.is-active:after {
  @apply .z-10 .opacity-30;
}

.c-partner {
  padding-top: 100%;
  @apply .text-0 .relative .w-full;
}

.c-partner__content {
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .z-10 .p-4 .flex .items-center .justify-center;
}

@screen md {
  .c-partner__content {
    @apply .p-5;
  }
}

@screen lg {
  .c-partner__content {
    @apply .p-6;
  }
}

.c-partner__picture {
  filter: grayscale(100%);
  @apply .transition-all .duration-300 .ease-in-out;
}

.c-partner:after {
  content: '';
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .bg-white .border .border-transparent .transition-all .duration-200 .ease-in-out;
}

.c-partner:hover:after, .c-partner:active:after, .c-partner.is-active:after {
  transform: scale(1.05814);
  @apply .z-40;
}

.c-partner:hover .c-partner__content, .c-partner:active .c-partner__content, .c-partner.is-active .c-partner__content {
  @apply .z-50;
}

.c-partner:hover .c-partner__picture, .c-partner:active .c-partner__picture, .c-partner.is-active .c-partner__picture {
  filter: grayscale(0);
}

.c-partners {
  @apply .text-0 .relative .border-t .border-l .border-grey-light;
}

.c-partners__list {
  @apply .flex .flex-wrap;
}

.c-partners__item {
  @apply .border-r .border-b .border-grey-light;
}

.c-picture {
  @apply .inline-block .relative .text-0 .h-full .overflow-hidden;
}

.c-picture__image {
  @apply .relative .h-full .transition-all .duration-500 .ease-in-out;
}

.c-picture__image.lazyload {
  filter: blur(4px);
  @apply .opacity-0;
}

.c-picture__image.lazyloaded {
  filter: blur(0);
  @apply .opacity-100;
}

.c-picture__image.lazyloaded + .c-picture__overlay {
  transform: translateX(100%);
}

.c-picture__overlay {
  transition: transform 0.8s 300ms cubic-bezier(0.25, 0.74, 0.22, 0.99);
  transform: translateX(0);
  @apply .bg-white .absolute .top-0 .right-0 .bottom-0 .left-0 .w-full .h-full;
}

.c-picture.is-absolute .c-picture__image {
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0;
}

.c-picture.is-relative .c-picture__image {
  @apply .relative;
}

.c-popup {
  @apply .text-0 .max-w-1.5xl .bg-white .p-8 .relative .w-full .mx-auto;
}

@screen md {
  .c-popup {
    @apply .p-12;
  }
}

@screen lg {
  .c-popup {
    @apply .p-14;
  }
}

@screen xl {
  .c-popup {
    @apply .p-20;
  }
}

.c-popup__close-btn {
  @apply .absolute .top-0 .right-0 .text-grey .m-7.5;
}

.c-popup__close-btn:hover .c-icon, .c-popup__close-btn:active .c-icon {
  @apply .text-secondary;
}

.mfp-container {
  @apply .px-6;
}

.mfp-bg {
  @apply .bg-secondary;
}

.mfp-fade.mfp-bg {
  @apply .opacity-0 .transition-all .duration-300 .ease-in-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  @apply .opacity-30;
}

.mfp-fade.mfp-bg.mfp-removing {
  @apply .opacity-0;
}

.mfp-fade.mfp-wrap .mfp-content {
  @apply .opacity-0 .transition-all .duration-300 .ease-in-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  @apply .opacity-100;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  @apply .opacity-0;
}

.c-presentation {
  min-height: 320px;
  @apply .text-0 .w-full .relative .flex .h-full;
}

.c-presentation__inner {
  @apply .px-12 .py-7.5 .bg-white .flex .h-full .z-10 .relative .overflow-hidden;
}

.c-presentation__inner:after {
  content: '';
  transform: translateY(0);
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .bg-white .transition-all .duration-500 .ease-in-out;
}

.c-presentation__body {
  @apply .pt-10 .pb-7.5;
}

.c-presentation__text-wrapper {
  @apply .opacity-0 .transition-all .duration-500 .ease-in-out;
}

.c-presentation__speaker, .c-presentation__title, .c-presentation__text {
  @apply .transition-all .duration-500 .ease-in-out;
}

.c-presentation__footer {
  @apply .relative .pt-7.5 .border-t-2 .border-grey-light;
}

.c-presentation__footer:before {
  content: '';
  @apply .absolute .w-5 .h-0.5 .left-0 .top-0 .-mt-0.5 .transition-all .duration-500 .ease-in-out;
}

.c-presentation:after {
  content: '';
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .-m-1.25 .opacity-0 .transition-all .duration-500 .ease-in-out;
}

.c-presentation:hover .c-presentation__text-wrapper, .c-presentation:active .c-presentation__text-wrapper {
  @apply .opacity-100;
}

.c-presentation:hover .c-presentation__footer:before, .c-presentation:active .c-presentation__footer:before {
  @apply .w-full;
}

.c-presentation:hover:after, .c-presentation:active:after {
  @apply .opacity-100;
}

.c-presentation.has-image .c-presentation__inner:after {
  content: '';
  transform: translateY(0);
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .bg-white .transition-all .duration-500 .ease-in-out;
}

.c-presentation.has-image .c-presentation__picture {
  transform: scale(1.1);
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .w-full .h-full .transition-all .duration-500 .ease-in-out;
}

.c-presentation.has-image .c-presentation__picture:after {
  content: '';
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .opacity-100 .bg-secondary;
}

.c-presentation.has-image:hover .c-presentation__inner:after, .c-presentation.has-image:active .c-presentation__inner:after {
  transform: translateY(-100%);
  @apply .-mt-px;
}

.c-presentation.has-image:hover .c-presentation__speaker, .c-presentation.has-image:hover .c-presentation__title, .c-presentation.has-image:hover .c-presentation__text, .c-presentation.has-image:active .c-presentation__speaker, .c-presentation.has-image:active .c-presentation__title, .c-presentation.has-image:active .c-presentation__text {
  @apply .text-white;
}

.c-presentation.has-image:hover .c-presentation__picture, .c-presentation.has-image:active .c-presentation__picture {
  transform: scale(1);
}

.c-progress-bar {
  @apply .text-0 .relative .w-0.5 .h-full .bg-white-30;
}

.c-progress-bar__progress {
  @apply .absolute .bg-white .top-0 .right-0 .bottom-0 .left-0 .h-0 .transition-all .duration-75 .ease-in-out;
}

.c-project {
  @apply .w-full .h-full .relative .text-0;
}

.c-project__wrapper {
  @apply .relative .h-full .overflow-hidden;
}

.c-project__inner, .c-project__overlay {
  @apply .p-10;
}

.c-project__inner {
  @apply .relative .h-full .z-10 .bg-white .border .border-grey-light .transition-all .duration-300 .ease-in-out;
}

.c-project__picture {
  width: 172px;
  height: 172px;
  @apply .relative .rounded-full .overflow-hidden .z-10 .transition-transform .duration-700 .ease-in-out;
}

.c-project__picture:after {
  content: '';
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .opacity-0 .bg-secondary .transition-all .duration-300 .ease-in-out;
}

.c-project__body {
  @apply .relative .text-center .transition-all .duration-300 .ease-in-out;
}

.c-project__overlay {
  transform: translateY(8%);
  @apply .text-center .-z-1 .absolute .top-0 .right-0 .bottom-0 .left-0 .opacity-0 .transition-all .duration-500 .ease-in-out;
}

.c-project__title, .c-project__text {
  @apply .transition-all .duration-500 .ease-in-out;
}

.c-project:after {
  content: '';
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .-m-1.25 .opacity-0 .transition-all .duration-500 .ease-in-out;
}

.c-project:hover .c-project__inner, .c-project:active .c-project__inner {
  @apply .border-transparent;
}

.c-project:hover .c-project__picture, .c-project:active .c-project__picture {
  transform: scale(4);
}

.c-project:hover .c-project__picture:after, .c-project:active .c-project__picture:after {
  @apply .opacity-30;
}

.c-project:hover .c-project__body, .c-project:active .c-project__body {
  @apply .opacity-0 .-z-1;
}

.c-project:hover .c-project__overlay, .c-project:active .c-project__overlay {
  transform: translateY(0);
  @apply .opacity-100 .z-30;
}

.c-project:hover .c-project__title, .c-project:hover .c-project__text, .c-project:active .c-project__title, .c-project:active .c-project__text {
  @apply .text-white;
}

.c-project:hover:after, .c-project:active:after {
  @apply .opacity-100;
}

.c-scroll-indicator {
  @apply .relative .flex .flex-col;
}

.c-scroll-indicator__label {
  margin-top: -44px;
  margin-left: -14px;
  transform: rotate(90deg);
  @apply .absolute .inline-block .text-base .text-white .font-bold .leading-tighter;
}

.c-scroll-indicator__icon {
  @apply .inline-block .relative .pt-3.5;
}

.c-scroll-indicator__icon:before {
  left: 50%;
  content: '';
  animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
  @apply .w-0.5 .h-5 .bg-white .absolute .top-0 .-ml-px .rounded-t-lg;
}

.c-scroll-indicator__arrow {
  @apply .text-white;
}

@keyframes elasticus {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  50.1% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}

.c-session {
  @apply .text-0 .relative .bg-white .p-8;
}

@screen md {
  .c-session {
    @apply .p-12;
  }
}

.c-slider {
  @apply .relative .text-0;
}

.c-slider__numbers {
  @apply .flex .items-end .-ml-4;
}

.c-slider__number {
  @apply .pl-4;
}

.c-slider__number.is-active .c-slider__number-label {
  @apply .text-white;
}

.c-slider__number-link:hover .c-slider__number-label, .c-slider__number-link:active .c-slider__number-label {
  @apply .text-white;
}

.c-slider__number-label {
  @apply .text-white-60 .transition-all .duration-300 .ease-in-out;
}

.c-slider__nav-list {
  @apply .flex .-ml-2.5;
}

.c-slider__nav-item {
  @apply .pl-2.5;
}

.c-slider__nav-button {
  @apply .w-12 .h-12 .rounded-full .border .border-grey .flex .items-center .justify-center .bg-transparent .text-grey-light;
}

.c-slider__nav-button:hover .c-icon, .c-slider__nav-button:active .c-icon {
  @apply .text-white;
}

.c-slider__nav-button--prev {
  transform: rotate(180deg);
}

.c-slider__nav-button--alt {
  @apply .border-grey-light;
}

.c-spec {
  @apply .text-0;
}

.c-spec__list {
  @apply .flex .flex-wrap .justify-between .-ml-2.5;
}

.c-spec__item {
  @apply .pl-2.5 .w-full;
}

@screen lg {
  .c-spec__item {
    @apply .w-auto;
  }
}

.c-spec__label, .c-spec__value {
  @apply .inline-block .text-lg .leading-snug .text-secondary;
}

@screen md {
  .c-spec__label, .c-spec__value {
    @apply .text-xl;
  }
}

.c-spec__label {
  @apply .font-black;
}

.c-specs {
  @apply .text-0;
}

.c-specs__list {
  @apply .-mb-5;
}

.c-specs__item {
  @apply .pb-5;
}

.c-text {
  @apply .text-lg .leading-snug;
}

@screen md {
  .c-text {
    @apply .text-xl;
  }
}

.c-text a {
  @apply .relative .pb-0.5 .font-bold .leading-tighter .text-secondary;
}

.c-text a:after {
  content: '';
  @apply .absolute .left-0 .bottom-0 .w-5 .h-0.5 .transition-all .duration-300 .ease-in-out;
}

.c-text a:hover:after, .c-text a:active:after {
  @apply .w-full;
}

.c-text figure a {
  @apply .pb-0;
}

.c-text figure a:after {
  @apply .hidden;
}

.c-text p,
.c-text ul,
.c-text ol {
  @apply .mb-7;
}

.c-text p:last-child,
.c-text ul:last-child,
.c-text ol:last-child {
  @apply .mb-0;
}

.c-text ul {
  @apply .relative;
}

.c-text ul li {
  @apply .relative .pl-3.5;
}

.c-text ul li:before {
  content: '';
  @apply .absolute .left-0 .top-0 .w-1 .h-1 .mt-2.75 .rounded-full;
}

.c-text ol {
  @apply .list-decimal .pl-5;
}

.c-text ol li::marker {
  @apply .font-normal;
}

.c-text ol ol li {
  list-style-type: lower-alpha;
}

.c-text figure {
  max-width: 100% !important;
  @apply .mb-5 .text-0;
}

@screen md {
  .c-text figure {
    @apply .mb-10;
  }
}

.c-text figure figcaption {
  @apply .text-sm .text-grey .mt-1 .italic;
}

.c-text figure:last-child {
  @apply .mb-0;
}

.c-text--small {
  @apply .text-base .leading-normal;
}

.c-think-tank {
  @apply .relative;
}

.c-think-tank__content {
  @apply .flex .justify-center .items-center .absolute .inset-0;
}

.c-think-tank:hover .c-think-tank__header, .c-think-tank:active .c-think-tank__header {
  @apply .opacity-0;
}

.c-think-tank:hover .c-think-tank__reveal, .c-think-tank:active .c-think-tank__reveal {
  @apply .opacity-100;
}

.c-think-tank:hover .c-think-tank__wrapper, .c-think-tank:active .c-think-tank__wrapper {
  @apply .mb-0;
}

.c-think-tank__reveal, .c-think-tank__wrapper, .c-think-tank__header {
  @apply .transition-all .duration-500 .ease-in-out;
}

.c-think-tank__reveal {
  @apply .flex .justify-center .items-center .absolute .inset-0 .opacity-0;
}

.c-think-tank__wrapper {
  max-width: 372px;
  @apply .-mb-64 .px-5;
}

.c-think-tank--blue .c-think-tank__reveal {
  background-color: rgba(26, 151, 213, 0.7);
}

.c-think-tank--green .c-think-tank__reveal {
  background-color: rgba(19, 150, 124, 0.7);
}

.c-topic {
  @apply .w-full .p-5;
}

.c-topic__wrapper {
  @apply .flex .items-center .gap-7;
}

.c-topic__picture {
  flex-shrink: 0;
  @apply .w-30.5 .h-30.5;
}

/* purgecss end ignore */
@tailwind utilities;
