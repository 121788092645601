.c-highlight {
    $c: &;

    @apply .text-0 .relative .bg-white .w-full;

    &__picture {
        @apply .h-full;
    }

    &__body {
        @apply .p-12;

        @screen md {
            @apply .p-0;
        }
    }
}
