.c-cookie-consent {
    z-index: -1;

    transform: translateY(100%);

    $c: &;

    @apply .fixed .right-0 .bottom-0 .left-0 .bg-grey-lighter .border .border-grey-light .mt-6 .mr-6 .ml-6 .text-center .p-6 .transition-all .duration-500 .ease-in-out;

    @screen md {
        @apply .left-auto .max-w-xs;
    }

    &__message {
        @apply .mb-4;
    }

    &.in-view {
        z-index: 9999;

        transform: translateY(0);

        @apply .mb-6;
    }
}
