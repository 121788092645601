.c-text {
    $c: &;

    @apply .text-lg .leading-snug;

    @screen md {
        @apply .text-xl;
    }

    a {
        @apply .relative .pb-0.5 .font-bold .leading-tighter .text-secondary;

        &:after {
            content: '';

            @apply .absolute .left-0 .bottom-0 .w-5 .h-0.5 .transition-all .duration-300 .ease-in-out;
        }

        &:hover,
        &:active {
            &:after {
                @apply .w-full;
            }
        }
    }

    figure {
        a {
            @apply .pb-0;

            &:after {
                @apply .hidden;
            }
        }
    }

    p,
    ul,
    ol {
        @apply .mb-7;

        &:last-child {
            @apply .mb-0;
        }
    }

    ul {
        @apply .relative;

        li {
            @apply .relative .pl-3.5;

            &:before {
                content: '';

                @apply .absolute .left-0 .top-0 .w-1 .h-1 .mt-2.75 .rounded-full;
            }
        }
    }

    ol {
        @apply .list-decimal .pl-5;

        li {
            &::marker {
                @apply .font-normal;
            }
        }

        ol {
            li {
                list-style-type: lower-alpha;
            }
        }
    }

    figure {
        max-width: 100% !important;

        @apply .mb-5 .text-0;

        @screen md {
            @apply .mb-10;
        }

        figcaption {
            @apply .text-sm .text-grey .mt-1 .italic;
        }

        &:last-child {
            @apply .mb-0;
        }
    }

    &--small {
        @apply .text-base .leading-normal;
    }
}
