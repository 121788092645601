.c-specs {
    $c: &;

    @apply .text-0;

    &__list {
        @apply .-mb-5;
    }

    &__item {
        @apply .pb-5;
    }
}
