// -----------------------------------------------------------------------------
// elements/link
// -----------------------------------------------------------------------------

a {
    outline: none;

    @apply .relative .inline-block .no-underline .transition-all .duration-300 .ease-in-out;

    &:focus {
        outline: none;
    }
}
