.c-partner {
    padding-top: 100%;

    $c: &;

    @apply .text-0 .relative .w-full;

    &__content {
        @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .z-10 .p-4 .flex .items-center .justify-center;

        @screen md {
            @apply .p-5;
        }

        @screen lg {
            @apply .p-6;
        }
    }

    &__picture {
        filter: grayscale(100%);

        @apply .transition-all .duration-300 .ease-in-out;
    }

    &:after {
        content: '';

        @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .bg-white .border .border-transparent .transition-all .duration-200 .ease-in-out;
    }

    &:hover,
    &:active,
    &.is-active {
        &:after {
            transform: scale(1.05813953);

            @apply .z-40;
        }

        #{$c} {
            &__content {
                @apply .z-50;
            }

            &__picture {
                filter: grayscale(0);
            }
        }
    }
}
