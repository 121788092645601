.c-icon {
    @apply .fill-current .transition-all .duration-300 .ease-in-out;

    &--arrow-down {
        width: 7px;
        height: 5px;
    }

    &--arrow-down--alt {
        width: 12px;
        height: 8px;
    }

    &--arrow-right {
        width: 5px;
        height: 7px;
    }

    &--long-arrow-right {
        width: 24px;
        height: 10px;
    }

    &--plus {
        width: 10px;
        height: 10px;
    }

    &--plus--alt {
        width: 17px;
        height: 17px;
    }

    &--download {
        width: 16px;
        height: 17px;
    }

    &--close {
        width: 20px;
        height: 20px;
    }
    &--twitter {
        width: 20px;
        height: 20px;
    }
    &--instagram {
        width: 20px;
        height: 20px;
    }
    &--linkedin {
        width: 20px;
        height: 20px;
    }
    &--pause {
        width: 19px;
        height: 21px;
    }
    &--play {
        width: 17px;
        height: 21px;
        padding-left: 2px;
    }
}
